export default {
    name: "Honor",
    data() {
        return {
            window: window,
            textData: [
                { title: "企业愿景", value: "匠心质造 百年传承" },
                { title: "企业使命", value: "安心治疗 安全变美" },
                {
                    title: "企业价值观",
                    value: "诚信、激情、发友至上、以人为本、职业、专业、爱岗敬业、家文化",
                },
            ],
            brandCulture: [{
                    url: require("../../assets/sincerity.png"),
                    title: "诚信",
                    text: "企业立业之本，为人立身之道。",
                },
                {
                    url: require("../../assets/responsibility.png"),
                    title: "责任",
                    text: "公司未来的卓越，靠每个人尽职尽责，竭尽全力。",
                },
                {
                    url: require("../../assets/innovate.png"),
                    title: "创新",
                    text: "只有具备燃烧般的热情，付出不亚于任何人的努力，才有可能成功。",
                },
                {
                    url: require("../../assets/struggle-hard.png"),
                    title: "拼搏",
                    text: "只有具备燃烧般的热情，付出不亚于任何人的努力，才有可能成功。",
                },
                {
                    url: require("../../assets/customer-first.png"),
                    title: "客户至上",
                    text: "践行以客户为中心，是我们所有工作的出发点。",
                },
                {
                    url: require("../../assets/people-oriented.png"),
                    title: "以人为本",
                    text: "我们发自内心尊重和培养每个员工，员工永远是公司最宝贵的资产。",
                },
                {
                    url: require("../../assets/study.png"),
                    title: "学习",
                    text: "学习不止是学校的事，而是一辈子的事，终身学习，不断进化。",
                },
                {
                    url: require("../../assets/cooperation.png"),
                    title: "协作",
                    text: "我们的事业不是靠一个人或一个部门，靠的是所有人的共同努力。",
                },
            ],

            brandInformation: [{
                    url: require("../../assets/huagai.png"),
                    title: "重磅新闻 ",
                    data: "2020.05",
                    text: "碧莲盛获得华盖资本战略投资",
                    color: "#B32D33",
                    Htmlurl: 'https://mp.weixin.qq.com/s/jSWHQGxtaLh_dhWCGiHF4Q'
                        //
                },
                {
                    url: require("../../assets/COVID-19-aid-fund.png"),
                    title: "ISHRS ",
                    data: "2016.10",
                    text: "碧莲盛成立新冠疫情援助基金，百万医疗物资援助战疫前线。",
                    color: "#23252E",
                    Htmlurl: 'https://www.cs.com.cn/sylm/jsbd/201801/t20180109_5659923.html'
                },
                {
                    url: require("../../assets/peoples-health.png"),
                    title: "企业活动 ",
                    data: "2020.01",
                    text: "碧莲盛董事长尤丽娜受邀参加人民网人民健康主办《两会健康特别栏目》",
                    color: "#252081",
                    Htmlurl: 'http://health.people.com.cn/n1/2020/0526/c14739-31724005.html'
                },
                {
                    url: require("../../assets/2020china-brand-list.png"),
                    title: "企业活动 ",
                    data: "2020.01",
                    text: "碧莲盛荣获第七届中国品牌创新发展论坛“2020中国品牌榜·诚信品牌奖”。",
                    color: "#252081",
                    Htmlurl: 'https://baijiahao.baidu.com/s?id=1694915656846266069&wfr=spider&for=pc'
                },
                {
                    url: require("../../assets/renminhuiketing.png"),
                    title: "企业活动 ",
                    data: "2020.01",
                    text: "碧莲盛董事长尤丽娜受邀参加《人民会客厅》",
                    color: "#252081",
                    Htmlurl: 'https://baijiahao.baidu.com/s?id=1693927525335117668&wfr=spider&for=pc'
                },
                {
                    url: require("../../assets/sanyaxinxiu.png"),
                    title: "企业活动 ",
                    data: "2020.01",
                    text: "碧莲盛NHT三亚星秀盛典于三亚海棠湾香格里拉酒店隆重举行",
                    color: "#252081",
                    Htmlurl: 'https://baijiahao.baidu.com/s?id=1702798394047696665&wfr=spider&for=pc'
                },
                {
                    url: require("../../assets/yqtx-gongyi.png"),
                    title: "企业活动 ",
                    data: "2020.01",
                    text: "碧莲盛携手鸿基金正式启动“益企同行公益行动”",
                    color: "#252081",
                    Htmlurl: 'https://www.cet.com.cn/wzsy/cyzx/2926479.shtml'
                },
            ],
            brandHonor1: [{
                    url: require("../../assets/chengxinpinpaijiang.png"),
                    text: "诚信品牌奖",
                    tip: "2020年3月。作为植发行业的企业代表，碧莲盛受邀出席第七届中国品牌创新发展论坛，并荣获“2020中国品牌榜·诚信品牌奖”。",
                    istip: false
                },
                {
                    url: require("../../assets/qiyezerendianfan.png"),
                    text: "企业社会责任行业典范奖",
                    tip: "2020年1月。第九届中国公益节在北京举行。碧莲盛植发凭借多年来为公益事业发展所做出的贡献和成效，荣获“2019年度企业社会责任行业典范”的称号。",

                    istip: false
                },
                {
                    url: require("../../assets/gongyirenwujiang.png"),
                    text: "公益人物奖",
                    tip: "2020年1月。第九届中国公益节，碧莲盛植发创始人尤丽娜女士被评为“2019年度公益人物”。尤丽娜女士公益格言：“做医疗的初心是一颗做公益的心，为人行事只有不忘初心,才能持之以恒。”",

                    istip: false
                },
                {
                    url: require("../../assets/iqf-jiechuqiyejiang.png"),
                    text: "IQF匠心质造杰出企业奖",
                    tip: "2020年7月。以“质·创未来”为主题的2020国际质造节在北京圆满落幕，碧莲盛植发凭借良好的公众综合评价和品牌影响力，荣获“2020匠心质造奖－杰出企业奖”。",
                    istip: false
                },
                {
                    url: require("../../assets/iqf-jiechurenwujiang.png"),
                    text: "IQF匠心质造杰出人物奖",
                    tip: "2020年7月。在以“质·创未来”为主题的2020国际质造节上，碧莲盛植发创始人尤丽娜女士多年来通过展示质造成果，传递质造精神，探寻企业高品质成长路径，荣获“2020匠心质造奖－杰出人物奖”。",

                    istip: false
                },
            ],
            brandHonor2: [{
                    url: require("../../assets/lingjunrenwujiang.png"),
                    text: "领军人物奖",
                    tip: "2020年8月，在中国品牌创新发展工程主办的第七届中国行业影响力品牌峰会上，碧莲盛入围2020行业影响力品牌榜，创始人兼董事长尤丽娜荣获植发行业“领军人物奖”。",
                    istip: false
                },
                {
                    url: require("../../assets/2020pinpai-chengxinjiang.png"),
                    text: "2020中国品牌榜·诚信品牌奖",
                    tip: "2021年3月,第七届中国品牌创新发展论坛在北京会议中心隆重召开。碧莲盛凭借良好的信誉口碑及对植发行业技术发展的贡献，荣获“2020中国品牌榜·诚信品牌奖”。",

                    istip: false
                },
                {
                    url: require("../../assets/100qiang.png"),
                    text: "未来医疗100强",
                    tip: "2021年4月，“第五届未来医疗100强”大会在苏州吴江召开，作为植发行业的代表，碧莲盛荣登中国医疗服务榜单100强，估值达35亿，同时这也是继2019年入选“未来医疗100强”之后，碧莲盛再次入选。",

                    istip: false
                },
                {
                    url: require("../../assets/2021jiechuzhizaopinpai.png"),
                    text: "2021杰出质造品牌奖",
                    tip: "2021年6月，碧莲盛作为植发行业的代表，再次受邀出席#国际品质节，并荣获“2021杰出质造品牌奖”。",

                    istip: false
                },
                {
                    url: require("../../assets/2021chuangxindianfan.png"),
                    text: "2021年度行业创新典范奖",
                    tip: "12月8日，以“数智引领未来”为主题的2021国际科创节在天津香格里拉酒店成功举办，碧莲盛受邀出席论坛，并荣获“2021年度行业创新典范奖”。",

                    istip: false
                },
            ],
            //底部
            bottomList: [{
                    url: require("../../assets/16.svg"),
                    text: " 2005年创立企业",
                },
                {
                    url: require("../../assets/32.svg"),
                    text: " 全国直营连锁",
                },
                {
                    url: require("../../assets/13.svg"),
                    text: " 13年连续参加世界植发大会",
                },
                {
                    url: require("../../assets/600.svg"),
                    text: " 600位医护团队",
                },
                {
                    url: require("../../assets/300.svg"),
                    text: " 16类植养方式",
                },
            ],
        };
    },
    methods: {
        tipShow(index) {

            this.brandHonor1.forEach(element => {
                element.istip = false
            });
            this.brandHonor2.forEach(element => {
                element.istip = false
            });
            this.brandHonor1[index].istip = true
        },
        tipShow1(index) {
            this.brandHonor1.forEach(element => {
                element.istip = false
            });
            this.brandHonor2.forEach(element => {
                element.istip = false
            });
            this.brandHonor2[index].istip = true
        },
		gotoHtml(value) {
		    window.location.href = value.Htmlurl
		}

    },
    created() {},
};